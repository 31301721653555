<template>
    <Index />
</template>

<script>
import Index from "./components/Index.vue";

export default {
    name: "App",
    components: {
        Index,
    },
};
</script>
